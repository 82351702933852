<template>
  <v-dialog v-model="modalEditar" persistent max-width="340px">
    <v-card>
      <v-card-title class="secondary white--text">
        Actualización de perfil
      </v-card-title>
      <v-card-text class="pt-12">
        <v-text-field
          v-model="item.email"
          rounded
          locale="es"
          dense
          outlined
          label="Correo electrónico"
        />
        <v-text-field
          v-model="item.cargo_funcional"
          rounded
          locale="es"
          dense
          outlined
          label="Cargo funcional"
        />
        <label class="text-h6 text-center">Fecha de caducidad</label>
        <v-date-picker
          class="primary"
          v-model="item.fecha_caducidad"
          locale="es"
          dense
          outlined
          :min="moment().format('YYYY-MM-DD')"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="$emit('cerrar')">Cerrar</v-btn>
        <v-btn
          @click.stop="showModarConfirm = true"
          :disabled="!item.fecha_caducidad"
          class="secondary"
        >
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationDialogComponent
      :show="showModarConfirm"
      btnConfirmar="Actualizar"
      :title="`¿Desea actualizar el empleado?`"
      @close="showModarConfirm = false"
      @confirm="actualizarEmpleado"
    />
  </v-dialog>
</template>
<script>
import moment from "moment";
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";

export default {
  name: "modalEditarCaducidadEmpleado",
  components: { ConfirmationDialogComponent },
  data: () => ({
    showModarConfirm: false,
    config_calendar: {
      visibility: "click",
    },
  }),
  methods: {
    async actualizarEmpleado() {
      console.log("this.item :>> ", this.item);
      await this.services.EmpleadosProveedor.editarEmpleado(
        this.usuario.id,
        this.item
      );
      this.showModarConfirm = false;
      this.$emit("cerrar");
    },
  },
  computed: {
    item: {
      get() {
        return {
          cargo_funcional: this.usuario.cargo_funcional,
          email: this.usuario.email,
          fecha_caducidad: this.usuario.fecha_caducidad,
        };
      },
      set(value) {
        this.item = value;
      },
    },
  },
  props: {
    modalEditar: {
      type: Boolean,
    },
    usuario: {
      type: Object,
    },
    fecha: {
      type: Date,
    },
  },
};
</script>
<style>
.v-picker__title {
  display: none !important;
}
</style>
